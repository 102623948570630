<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('users.providers.title')"
        :sub-title="$t('users.providers.desc')"
    />

    <div>
      <filter-header
          :content="content"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderProviders"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
                v-if="getAllUsers"
                :per-page="filterData.per_page"
                :items="getAllUsers"
                :fields="fields"
                :status="accountStatus"
                :custimized-items="[
                { name: 'name' },
                { name: 'created_at' },
                { name: 'action' },

              ]"
            >
              <template slot="name" slot-scope="{ data }">
                <div class="d-flex justify-center align-items-center">
                  <b-avatar :src="data.item.image"/>
                  <span class="text-font-main mx-3">{{ data.item.name }}</span>
                </div>
              </template>
              <template slot="created_at" slot-scope="{ data }">
                <div class="d-flex justify-center align-items-center">
                    <span class="text-font-main mx-3">{{
                        moment(data.item.created_at).format('ll')
                      }}</span>
                </div>
              </template>


              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                    variant="background"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-14"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1"/>
                  </template>
                  <b-dropdown-item
                      link-class="py-0min-height: 52px;"
                      @click="providerDetail(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                      link-class="py-0min-height: 52px;"
                      @click="chatWithProvider(data.item)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <chat2-icon  class="mx-2" />
                      {{ $t('common.chat') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                      v-if="data.item.status !=='default'"
                      link-class="py-0min-height: 52px;"
                      @click="toggleProviderStatus(data.item.id)"
                  >
                    <span class="text-regular-14 text-danger" v-if="data.item.blocked_by ===null">

                      <stop2-icon class="mx-2"/>
                      {{ $t('common.stop') }}
                    </span>
                    <span class="text-regular-14 text-main" v-if="data.item.blocked_by !==null">

                      <tick-squire-icon class="mx-2"/>
                      {{ $t('common.to_active') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>

              </template>
            </w-tables>
            <wameed-no-data
                v-if="getAllUsers && getAllUsers.length < 1"
                icon="nodata-icon"
                :title="$t('packages.no_data')"
                :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotalUsers"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalUsers.totalItems"
              :per_page="filterData.per_page"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';

import rate from 'vue-rate/src/Rate.vue';

export default {
  components: {
    rate,
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      filterData: {
        status: '',
        rate: '',
        subscription: '',
        type: '',
        page: 1,
        per_page: 20,
        order_by: 'name',
        search: '',
      },
      content: [
        {
          title: 'status',
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          data: [
            {
              id: 'active',
              title: 'active',
            },
            {
              id: 'not_active',
              title: 'not_active',
            },
          ],
        },
        {
          title: 'provider_type',
          key: 'type',
          id: 'id',
          name: 'name',
          selectedData: [],
          data: [],
        },
        {
          title: 'subscription_type',
          key: 'subscription',
          id: 'id',
          name: 'name',
          selectedData: [],
          data: [],
        },
      ],
      fields: [
        {
          key: 'name',
          label: this.$i18n.t('table.user_name'),
          sortable: true,
        },
        {
          key: 'account_type',
          label: this.$i18n.t('table.type'),
          sortable: true,
        },
        {
          key: 'email',
          label: this.$i18n.t('table.email'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$i18n.t('table.subscription_date'),
          sortable: true,
        },
        {
          key: 'subscription_type',
          label: this.$i18n.t('table.subscription_type'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('table.subscription_status'),
          sortable: true,
        },
        {key: 'action', label: ''},
      ],
      accountStatus: [
        {
          active: 'active',
          not_active: 'not_active',

        },
        {
          active: 'success',
          not_active: 'input',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllUsers: 'getAllUsers',
      getTotalUsers: 'getTotalUsers',
      getFilterData: 'getFilterData',
    }),
  },
  watch: {
    getFilterData(newVal) {
      if (newVal) {
        this.content[2].data = newVal.subscriptions ? newVal.subscriptions : [];
        this.content[1].data = newVal.types ? newVal.types : [];
      }
    },
  },
  created() {
    this.loadData();
    this.loadFilterData('reviews_providers');
  },
  methods: {
    ...mapActions({
      loadProviders: 'loadProviders',
      toggleProvider:'toggleProvider',
      loadFilterData: 'loadFilterData',
    }),
    chatWithProvider(value) {
      const userInfo = {
        id: value.id.toString(),
        name: value.name,
        avatar: value.image,
      };
      this.$router.push({
        name: 'chats',
        params: {
          userInfo,
        },
      });
    },

    toggleProviderStatus(id) {
      this.toggleProvider(id).then(() => {
        this.loadData();
      });
    },
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadProviders(this.filterData);
    },
    providerDetail(id) {
      this.$router.push({
        name: 'users-providers-details',
        params: {lang: this.$i18n.locale, id: id},
      });
    },
    applyFilter() {
      this.content.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item, i) => {
        this.content[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderProviders(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
